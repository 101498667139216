import React, { useEffect, useRef, useState } from 'react';
import { getImage } from 'gatsby-plugin-image';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    container,
    noCoverImage,
    noHoverImage,
    cover,
    ratio,
    content,
    categoryName,
    hoverContent,
    hoverRatio,
} from './category-card.module.scss';
import { ICategory } from '../../models/category.model';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import { getMediaWithRelation } from '../../utils/get-relation';

import RatioImage from '../atoms/ratio-image';

const { relations, translationKeys } = config;
const startFontSize = 40;
const fontToContainerProportion = 12.925;

export interface ICategoryCardProps {
    category: ICategory;
}

const CategoryCard: React.FC<ICategoryCardProps> = ({ category }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState<number>(1);
    const { name } = useTranslation(category, translationKeys.category);
    const { media, slug } = category;

    const coverImageMedia = getMediaWithRelation(media, relations.coverImage, false);
    const hoverImageMedia = getMediaWithRelation(media, relations.secondaryImage, false);

    const coverImage = coverImageMedia?.remoteImage && getImage(coverImageMedia.remoteImage);
    const hoverImage = hoverImageMedia?.remoteImage && getImage(hoverImageMedia.remoteImage);

    useEffect(() => {
        if (!containerRef.current) return;

        const handleResize = () => {
            const containerWidth = containerRef.current?.offsetWidth;
            if (!containerWidth) return;
            const newFontSize = containerWidth / fontToContainerProportion;
            setFontSize(newFontSize > startFontSize ? startFontSize : newFontSize);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [containerRef]);

    return (
        <Link
            to={slug}
            className={`
                ${container} 
                ${coverImage ? '' : noCoverImage}
                ${hoverImage ? '' : noHoverImage}
            `}
        >
            <RatioImage image={coverImage} className={cover} ratioClass={ratio} />
            <div className={content} ref={containerRef} style={{ fontSize: `${fontSize}px` }}>
                <h2 className={categoryName}>{name}</h2>
            </div>
            <RatioImage
                image={hoverImage}
                className={hoverContent}
                ratioClass={hoverRatio}
                objectFit="contain"
            />
        </Link>
    );
};

export default CategoryCard;
