import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, list } from './categories.module.scss';
import { config } from '../config';
import { ICategory } from '../models/category.model';
import { IBanner } from '../models/banner.model';
import { ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { getMediaWithRelation } from '../utils/get-relation';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import CategoryCard from '../components/molecules/category-card';
import List from '../components/organisms/list';

const { translationKeys, relations, categoryBusinessMeanings } = config;

interface ICategoriesProps {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        allCategory: { edges: { node: ICategory }[] };
    };
    readonly pageContext: ISitePageContext & {
        businessMeaning: number;
    };
}

const Categories: React.FC<ICategoriesProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { bannersCollection, allCategory } = data;
    const { businessMeaning } = pageContext;

    const seoFallbackKey =
        businessMeaning === categoryBusinessMeanings.brand ? 'brands' : 'categories';

    const banner = bannersCollection?.banners[0];
    const { title, description } = useTranslation(banner, translationKeys.banner);

    const categories = allCategory.edges.map(({ node }) => node);

    return (
        <>
            <SEO
                title={title || t(`${seoFallbackKey}.title`)}
                description={description || t(`${seoFallbackKey}.description`)}
                image={
                    banner?.media && getMediaWithRelation(banner.media, relations.mainImage)?.url
                }
            />
            <MainLayout
                className={layout}
                bannersData={banner && { banner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <List className={list}>
                    {categories.map((category) => {
                        return (
                            <li key={`category-item-${category.categoryId}`}>
                                <CategoryCard category={category} />
                            </li>
                        );
                    })}
                </List>
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query($language: String!, $placeId: String!, $categoryIds: [Int!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        allCategory(
            filter: { categoryId: { in: $categoryIds } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...categoryCardFields
                }
            }
        }
    }
`;

export default Categories;
